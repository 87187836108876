import Vue from 'vue/dist/vue.esm';
import axios from 'axios';
import Vuency from 'vuency';
import camelize from 'lodash/camelCase';
import moment from 'moment';

Vue.use(Vuency);

export default {
  data: () => {
    return {
      query: null,
      category: false,
      editor: false,
      tags: [],
      order: 'date_desc',
      dateFrom: null,
      dateTo: null,
    };
  },

  beforeMount() {
    this.updateFiltersState();
  },

  computed: {
    filters() {
      let uriChunks = [];

      [
        'query',
        'category',
        'editor',
        'tags',
        'order',
        'date_from',
        'date_to',
      ].forEach((filter) => {
        let value = this[camelize(filter)];

        if (!value) {
          return;
        }

        if (Array.isArray(value)) {
          value = value.filter((i) => i).join(',');
        }

        if (filter.indexOf('date') > -1) {
          value = moment(value).format('DD-MM-YYYY');
        }

        if (value && value !== '') {
          uriChunks.push([filter, value].join('='));
        } else {
          uriChunks = uriChunks.filter((i) => i.indexOf(filter) === -1);
        }
      });

      return uriChunks.join('&');
    },
  },

  methods: {
    removeItem(item, listType) {
      if (listType === 'tags') {
        this.tags = this.tags.filter((t) => t !== item);
      } else if (listType === 'category') {
        this.category = this.category.filter((t) => t !== item);
      }
      this.applyFilter.run();
    },

    setFilter(filter = false, value = false) {
      if (filter) {
        this[filter] = value;
      }

      this.applyFilter.run();
    },

    updateFiltersState() {
      const filters = new URLSearchParams(document.location.search);

      for (let p of filters) {
        let filterName = camelize(p[0]);
        let filterValue = p[1];

        this[filterName] = this._normalizeValue(filterName, filterValue);
      }
    },

    // Helpers
    _normalizeValue(filterName, value) {
      if (Array.isArray(this[filterName])) {
        value = value.split(',');
      }

      if (filterName.indexOf('date') > -1) {
        value = moment(value, 'DD-MM-YYYY').toDate();
      }

      return value;
    },
  },

  tasks(t) {
    return {
      applyFilter: t(function* () {
        const url = [window.location.pathname, this.filters].join('?');

        const { data } = yield axios.get(url, {
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
            Accept: 'text/javascript',
          },
        });

        eval(data);

        // Some turbolinks magic ...
        window.history.pushState({ __turbolinksVisit: true, url }, '', url);
      }).flow('enqueue'),
    };
  },
};
