<template>
  <div>
    <div @click="isOpen = true">
      <slot name="trigger" :isOpen="isOpen">click</slot>
    </div>
    <transition name="accordion" @enter="enter" @after-enter="leave">
      <div v-if="isOpen">
        <slot name="body"/>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isOpen: false
    };
  },
  methods: {
    enter(el) {
      el.style.height = el.scrollHeight + 'px'
    },
    leave(el) {
      el.style.height = ''
    }
  }
};
</script>
