<template>
  <div>
    <multiselect
      v-model="selected"
      :options="sortedOptions"
      :multiple="multiple"
      track-by="value"
      label="name"
      :placeholder="placeholder"
      :searchable="searchable"
      :loading="isLoading"
      @search-change="asyncFind"
      @input="select"
    >
      <template slot="selection" slot-scope="{ values, search, isOpen }">
        <span v-if="values.length && !isOpen">
          Tags ({{ values.length }})
        </span>
      </template>

      <!-- It’s used for a different markup for selected options -->
      <template slot="tag">{{ value.count }}</template>
      <template slot="noOptions"> Please type a tag to search </template>
    </multiselect>
  </div>
</template>

<script>
import axios from 'axios';
import Multiselect from 'vue-multiselect';
import uniqBy from 'lodash/unionBy';
import sortBy from 'lodash/sortBy';

export default {
  components: { Multiselect },
  props: {
    onSelect: {
      type: Function,
      default: () => false,
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: 'Select option',
    },
    value: {
      type: [String, Array, Boolean],
      default: () => null,
    },
    defaultOptions: {
      type: Array,
      default: () => [],
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      selected: null,
      isLoading: false,
      options: [],
    };
  },

  computed: {
    sortedOptions() {
      return sortBy(this.options, 'name');
    },
  },

  watch: {
    value: function () {
      this._updateValue();
    },
  },

  mounted() {
    if (this.defaultOptions) {
      this.options = this.defaultOptions;
    }

    this._updateValue();
  },

  methods: {
    select() {
      this.onSelect(this.selected);
    },

    async asyncFind(query) {
      this.isLoading = true;

      const { data } = await axios.get(`/tags?query=${query}`, {
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
        },
      });

      this.options = data.map((i) => new Object({ name: i, value: i }));
      this.isLoading = false;
    },

    // Helpers
    _updateValue() {
      if (this.value) {
        if (!Array.isArray(this.value)) {
          this.selected = this.options.find((o) => o.value === this.value);
        } else {
          let options = this.value.map(
            (option) =>
              new Object({
                name: option,
                value: option,
              })
          );

          this.selected = options;
          this.options = uniqBy(
            options.concat(this.defaultOptions || []),
            (x) => x.value
          );
        }
      }
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect,
.multiselect__select,
.multiselect__tags {
  height: 56px;
  border-color: #9b9b9b;
}

.multiselect__tags {
  display: flex;
  align-items: center;
  font-size: 16px;
  padding-top: 0;
  padding-bottom: 0;
}

.multiselect__content-wrapper {
  width: auto;
  min-width: 100%;
  border: 1px solid #9b9b9b;
}

.multiselect__placeholder,
.multiselect__single {
  margin-left: 5px;
  margin-bottom: 0;
}

.multiselect__input {
  margin-bottom: 0;
}

.multiselect__select::before {
  top: 56%;
}

.multiselect__content-wrapper {
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11),
    0 5px 15px 0 rgba(0, 0, 0, 0.08);
}
</style>
